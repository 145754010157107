<template>
  <div class="setup" v-if="step_progress">
    <div class="setup-modal">
      <h1 class="setup-modal-title" v-if="title">{{ title }}</h1>
      <!-- <h2 class="setup-modal-description" v-if="description">
        {{ description }}
      </h2> -->
      <main class="step-1-container" v-if="step === 1">
        <img src="/img/setup-modal/panel-screenshot.png" />
      </main>
      <main class="step-2-container custom-scrollbar" v-if="step === 2">
        <a
          href="#"
          :class="[
            'theme-card',
            selectedTheme == theme.id ? 'selected-card' : '',
          ]"
          v-for="theme in themes.themes"
          :key="theme.id"
          @click.prevent="selectTheme(theme)"
        >
          <!-- <a :href="theme.demo_url" target="_blank" class="theme-card-view">
            <i class="fas fa-eye"></i>
            <span>Canlı Önizle</span>
          </a> -->
          <div class="position-relative">
            <img class="theme-card-image" :src="theme.thumbnail" />
          </div>
          <div class="theme-card-footer">
            <span class="theme-card-name">{{ theme.title }}</span>
            <button
              class="theme-card-button selected-theme-button"
              v-if="selectedTheme == theme.id"
            >
              Seçildi
              <i class="fas fa-check"></i>
            </button>
          </div>
        </a>
      </main>
      <main class="step-3-container" v-if="step === 3">
        <h5>Demo Verileri Yükleme İster Misin?</h5>
        <p class="text-center">
          Seçtiğin temaya ait demo verileri yükleyerek mağazanı daha <br />
          kolay ve hızlı oluşturabilirsin.
        </p>
        <button
          class="demo-import"
          @click="submitDemoData"
          :disabled="demoDataLoading"
        >
          {{ demoDataBtnText }}
          <i
            :class="
              demoDataLoading ? 'fas fa-spinner fa-spin' : 'fas fa-file-import'
            "
          ></i>
        </button>
        <p style="color: #3f51b5" class="mt-3" v-if="demoDataLoading">
          Demo verileri yükleniyor lütfen bekleyiniz...
        </p>
      </main>
      <main class="step-3-container" v-if="step === 4">
        <WeImageUploader
          v-model="logo"
          v-bind:image-required="false"
          title=""
          ref="imageuploader"
          :hideDropzone="logo.files.length"
          metaVisible="false"
        />
      </main>
      <main class="step-4-container" v-if="step === 5">
        <WeInput label="Site Başlığı" v-model="site_title" />
        <WeInput label="E-Posta Adresi" v-model="email" />
        <WeInput label="Telefon Numarası" v-model="phone" />
        <WeInput label="WhatsApp Destek Numarası" v-model="whatsapp_phone" />
      </main>
      <main class="step-5-container" v-if="step === 6">
        <div class="col-12 col-lg-6">
          <WeInput
            v-model="facebook"
            v-bind:prepend="`<i class='fab fa-facebook-f fa-lg'></i>`"
            placeholder="@"
            label="Facebook"
          />
          <WeInput
            v-model="twitter"
            v-bind:prepend="`<i class='fab fa-twitter fa-lg'></i>`"
            placeholder="@"
            label="Twitter"
          />
          <WeInput
            v-model="instagram"
            v-bind:prepend="`<i class='fab fa-instagram fa-lg'></i>`"
            placeholder="@"
            label="Instagram"
          />
          <WeInput
            v-model="youtube"
            v-bind:prepend="`<i class='fab fa-youtube fa-lg'></i>`"
            placeholder="@"
            label="YouTube"
          />
          <WeInput
            v-model="linkedin"
            v-bind:prepend="`<i class='fab fa-linkedin-in fa-lg'></i>`"
            placeholder="@"
            label="LinkedIn"
          />
        </div>
      </main>
      <h3 class="setup-modal-bottom-description" v-if="bottomDescription">
        {{ bottomDescription }}
      </h3>
      <div class="setup-modal-footer">
        <button
          class="setup-modal-prev"
          v-if="step !== 1 && !submit"
          @click="prev"
          :disabled="prevButtonDisable"
        >
          {{ prevButtonText }}
        </button>
        <button
          class="setup-modal-next"
          @click="next"
          v-if="!submit"
          :disabled="nextButtonDisable"
        >
          {{ nextButtonText }} <i class="fas fa-arrow-alt-circle-right"></i>
        </button>
        <button class="setup-modal-next" v-if="submit">
          Kurulum Yapılıyor <i class="fas fa-spinner fa-spin"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      title: "Hoşgeldiniz",
      description: "Yönetim panelinize hoş geldiniz.",
      bottomDescription:
        "Mağazanızın gerekli ayarlarının yapılabilmesi için size birkaç soru soracağız.",
      nextButtonText: "Başla",
      prevButtonText: "Geri Dön",
      step: 1,
      selectedTheme: null,
      logo: { files: [], filesData: null },
      site_title: null,
      email: null,
      phone: null,
      demoDataBtnText: "Yükle",
      demoDataLoading: false,
      whatsapp_phone: null,
      nextButtonDisable: false,
      prevButtonDisable: false,
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
      linkedin: null,
      step_progress: false,
      submit: false,
    };
  },
  methods: {
    ...mapActions("setup", ["create"]),
    ...mapActions("themes", ["getThemes", "importThemeData"]),
    ...mapActions("logo", ["upload"]),
    selectTheme(theme) {
      this.selectedTheme = theme.id;
    },
    submitDemoData() {
      this.demoDataLoading = true;
      this.demoDataBtnText = "Yükleniyor";
      this.nextButtonDisable = true;
      this.prevButtonDisable = true;
      this.importThemeData({
        themeId: this.selectedTheme,
        onSuccess: (result) => {
          if (result?.data?.status == "success") {
            this.sendForm();
          }
        },
        onFinish: () => {},
      });
    },
    next() {
      if (this.step === 6) {
        this.completeSetup();
      } else {
        if (this.step == 2 && !this.selectedTheme) {
          this.$swal({
            title: "Opss...",
            text: "Lütfen bir tema seçiniz",
            icon: "warning",
          });
        } else {
          this.triggerStep("next");
        }
      }
    },
    prev() {
      this.triggerStep("prev");
    },
    triggerStep(method, step = null) {
      if (step) {
        this.step = step;
      } else {
        if (method === "next") {
          this.step += 1;
        } else {
          this.step -= 1;
        }
      }

      if (this.step === 1) {
        this.nextButtonText = "Başla";
        this.title = "Hoşgeldiniz";
        this.bottomDescription =
          "Mağazanızın gerekli ayarlarının yapılabilmesi için size birkaç soru soracağız.";
      }

      if (this.step === 2) {
        this.nextButtonText = "Devam Et";
        this.title = "Tema Seçimi";
        this.description = "Mağazanız için size uygun temayı seçebilirsiniz.";
        this.bottomDescription =
          "Mağaza görüntüleri temsilidir. Tema içeriklerinizi özelleştirebilirsiniz.";
      }
      if (this.step === 3) {
        this.demoDataLoading = false;
        this.demoDataBtnText = "Yükle";
        this.nextButtonText = "Atla";
        this.title = "Demo Veriler";
        this.bottomDescription = null;
      }

      if (this.step === 4) {
        this.nextButtonText = "Atla";
        this.title = "Mağaza Logosu";
        this.bottomDescription =
          "Logonuzu Genel Ayarlar bölümünden de değiştirebilirsiniz.";
      }

      if (this.step === 5) {
        this.nextButtonText = "Devam Et";
        this.title = "Mağaza Bilgileri";
        this.bottomDescription =
          "Bu bilgileri Genel Ayarlar bölümünden de düzenleyebilirsiniz.";
      }

      if (this.step === 6) {
        this.nextButtonText = "Tamamla";
        this.title = "Sosyal Medya Bilgileri";
        this.bottomDescription =
          "Bu bilgileri Genel Ayarlar bölümünden de düzenleyebilirsiniz.";
      }
    },
    sendForm(logoUrl) {
      this.create({
        form: {
          theme: this.selectedTheme ?? null,
          logo: logoUrl ?? null,
          site_title: this.site_title ?? null,
          email: this.email ?? null,
          phone: this.phone ?? null,
          whatsapp_phone: this.whatsapp_phone ?? null,
          facebook: this.facebook
            ? "https://facebook.com/" + this.facebook
            : null,
          twitter: this.twitter ? "https://twitter.com/" + this.twitter : null,
          instagram: this.instagram
            ? "https://instagram.com/" + this.instagram
            : null,
          youtube: this.youtube ? "https://youtube.com/" + this.youtube : null,
          linkedin: this.linkedin
            ? "https://linkedin.com/" + this.linkedin
            : null,
        },
        onSuccess: () => {
          this.$toast.success("Mağaza kurulumunuz başarıyla tamamlandı.");
          this.step_progress = false;
          this.submit = false;

          const stored = JSON.parse(localStorage.getItem("session"));

          stored.config["app.is_new"] = 0;
          stored.config["site.logo"] = logoUrl || "";

          localStorage.setItem("session", JSON.stringify(stored));
        },
        onError: () => {
          this.$toast.error("Mağaza kurulumunuzda bir sorun yaşandı.");
          this.submit = false;
        },
        onFinish: () => {},
      });
    },
    async completeSetup() {
      this.submit = true;

      const image = this.logo.files;

      if (image && image.length) {
        await this.upload({
          file: image[0].file,
          onSuccess: (result) => {
            if (result && result.data && result.data.url) {
              this.sendForm(result.data.url);
            }
          },
          onError: () => {
            this.$toast.error("Logonuz yüklenirken bir sorun oluştu.");
            this.submit = false;
          },
        });
      } else {
        this.sendForm();
      }
    },
  },
  mounted() {
    let is_new = 0;

    if (this.session.config) {
      is_new = this.session?.config["app.is_new"] || false;
    }

    if (is_new || is_new === 1 || is_new === "1") {
      this.getThemes();
      this.step_progress = true;
    }
  },
  computed: {
    ...mapState(["session", "themes"]),
  },
};
</script>

<style lang="scss">
#confetti-canvas {
  z-index: 9999;
}

.setup {
  background-color: #000000c7;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
}

.setup-modal {
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 40px;
  margin: 24px;
}

.setup-modal-title {
  font-size: 30px;
  font-weight: bold;
  color: #3f51b5;
  text-align: center;
}

.setup-modal-description {
  font-size: 18px;
  margin-top: 10px;
  text-align: center;
}

.setup-modal-next {
  margin-left: auto;
}

.setup-modal-next:disabled,
.setup-modal-prev:disabled {
  background: gray;
}

.setup-modal-prev:disabled {
  color: #fff;
}

.setup-modal-next,
.setup-modal-prev {
  border: none;
  background-color: #3f51b5;
  color: #fff;
  font-weight: bold;
  border-radius: 8px;
  padding: 8px 24px;
}

.demo-import {
  border: 2px solid;
  background-color: transparent;
  color: #3f51b5;
  font-weight: bold;
  border-radius: 8px;
  font-size: 25px;
  margin: 5px 0px;
  padding: 8px 24px;
}

.setup-modal-prev {
  background-color: rgb(231, 232, 255);
  color: #3953e7;
}

.setup-modal-next:hover {
  background-color: #3953e7;
}

.setup-modal-prev:hover {
  background-color: rgb(223, 223, 255);
}

.setup-modal-next:focus,
.setup-modal-prev:focus {
  outline: 0;
}

.setup-modal-bottom-description {
  font-size: 18px;
  max-width: 400px;
  text-align: center;
  margin-bottom: 24px;
}

main {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.setup-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
}

.setup-modal-next > i {
  font-size: 14px;
  margin-left: 4px;
}

// step 1 start
.step-1-container img {
  max-width: 1000px;
  width: 100%;
}

// step 1 end

// step 2 start

.step-2-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow-y: auto;
  max-height: calc(100vh - 400px);
  max-width: 740px;
}

.step-2-container .theme-card {
  text-decoration: none;
  color: #000;
  box-shadow: 0 0 16px #ccc;
  max-width: 600px;
  height: 400px;
  width: 100%;
  flex-wrap: wrap;
  margin: 16px;
  border-radius: 16px;
  font-weight: bold;
  padding-bottom: 12px;
  position: relative;
  border: 2px solid transparent;
  overflow: hidden;
}

.step-2-container .theme-card-image {
  transition: 2s ease-in-out all;
  width: 100%;
  height: 350px;
  max-height: 350px;
  object-fit: cover;
  object-position: top;
  margin-bottom: 12px;
  border-radius: 16px;
}

.step-2-container .theme-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 24px;
}

.step-2-container .theme-card-button {
  border: none;
  background-color: #2e9210;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  padding: 2px 16px;
  font-size: 15px;
}

.step-2-container .theme-card-button:hover {
  background-color: #36a814;
}

.step-2-container .theme-card-button:focus {
  outline: none;
}

.step-2-container .theme-card-view i {
  font-size: 20px;
  margin-top: 4px;
  color: #3f51b5;
}

.step-2-container .theme-card-view {
  padding: 8px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: calc(50% - 50px);
  width: 100%;
  cursor: pointer;
  display: flex;
  opacity: 0;
  visibility: hidden;
  text-decoration: none;
  width: max-content;
  left: calc(50% - 50px);
  border-radius: 8px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.74);
  font-size: 15px;
  z-index: 2;
}

.step-2-container .theme-card-view:hover {
  color: #3f51b5;
  background-color: #fff;
}

.step-2-container .theme-card:hover .theme-card-view {
  opacity: 1;
  visibility: visible;
}

.step-2-container .theme-card:hover .theme-card-image {
  object-position: bottom;
}

.selected-card {
  color: #3953e7 !important;
  border: 2px solid #3953e7 !important;
}

.selected-theme-button {
  background-color: #3953e7 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.selected-theme-button i {
  font-size: 12px;
}

// step 2 end

// step 4 start
.step-4-container {
  width: 100%;
}

.step-4-container > div {
  width: 100%;
}
// step 4 end

// step 5 start
.step-5-container {
  width: 100%;
}

.step-5-container > div {
  width: 100%;
  max-width: 100%;
}

// step 5 end

.swal2-container {
  z-index: 99999999999;
}
</style>